<template lang="pug" functional>
span(
  :class="[...props.classes, {rounded: props.rounded}]"
  :data-test="props.dataTest"
).count-item
  v-tooltip(:right="true" v-if="!props.hideTooltip")
    template(v-slot:activator="{ on }")
      div(v-on="on" ).full-width
        slot
    span {{ props.tooltip }}

  slot(v-else)
</template>

<script>
export default {
  props: {
    top: {
      type: Boolean,
      default: true
    },
    right: Boolean,
    classes: Array,
    tooltip: String,
    dataTest: String,
    hideTooltip: Boolean,
    rounded: Boolean,
  }
}
</script>

<style lang="scss">
.count-item {
  text-align: center;
  color: $label-color;
  border-right: 1px solid $border-color;

  &:not(.rounded) {
    padding: 4px 0;
    width: 25%;
  }

  &.rounded {
    border-radius: 8px;
    padding: 1px 2px;
    white-space: nowrap;
    flex-shrink: 0;
    min-width: 20px;
  }

  &_queue {
    color: #79880E;
  }
  &_mijn {
    color: #3c16e9;

    &.rounded {
      border: 1px solid #E9E4FF;
    }
  }
  &_slots {
    border: 1px solid $warning-color;
    color: $warning-color;

    &.rounded {
      border: 1px solid #FFE9CC;
    }
  }
}
</style>