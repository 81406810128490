<template lang="pug">
  div.counts
    count-item(
      tooltip="Candidates"
      right
      :data-test="DATA_TEST.CANDIDATES_COUNT"
      :classes="['green--text']"
    )
      template(v-if="!hideMaxStudent")
        span {{ item.candidatesCount }}/
        template(v-if="typeof item.maxStudents === 'number'")
          max-students(
            :maxStudents="item.maxStudents"
            :ID="item.ID"
            @updated="$emit('updated', $event)"
          )
        template(v-else) -

    count-item(
      tooltip="TOP CBR"
      hideTooltip
      :classes="['count-item_queue']"
      :data-test="DATA_TEST.TOP_CBR"
    )
      v-tooltip(top )
        template(v-slot:activator="{ on }")
          span(v-on="on")
            template {{ item.topCbrReserveQueueCount }}
        span Reserve queue
      v-tooltip(top )
        template(v-slot:activator="{ on }")
          span(v-on="on")
            template | {{ item.topCbrExchangeQueueCount }}
        span Exchange queue

    count-item(
      tooltip="MIJN CBR" :classes="['count-item_mijn']"
      :data-test="DATA_TEST.MIJN_CBR"
    )
      template(v-if="typeof item.mijnCbrQueueCount === 'number'") {{ item.mijnCbrQueueCount }}
      template(v-else) -

    count-item(
      tooltip="Slots"
      :classes="['count-item_slots']"
      :data-test="DATA_TEST.SLOTS"
    ) {{ item.slotsCount }}
    v-tooltip(top v-if="false")
</template>

<script>
import { CM_DATA_TEST } from '../core/cm-const'
import CountItem from "./CMCourseCountItem.vue"

export default {
  props: {
    item: {
      Object,
      required: true,
    },
    hideMaxStudent: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    console.log('item', this.item)
  },

  data: () => ({
    DATA_TEST: CM_DATA_TEST.COUNTS
  }),

  components: {
    maxStudents: () => import('./cm_course/CMMaxStudents.vue'),
    countItem: CountItem
  }
}
</script>

<style lang="scss" scoped>
.counts {
  display: flex;
  align-items: center;
  border-top: 1px solid $border-color;
  position: relative;

  &__item {
    padding: 4px 0;
    width: 25%;
    text-align: center;
    color: $label-color;
    border-right: 1px solid $border-color;

    &:last-child {
      border-right: unset;
    }

  }
}
</style>
